// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
@import './assets/css/variables';

$chev-blue: (
    100: $luncolorlight,
    500: $luncolor,
    700: $luncolor700,
    900: $luncolordark,
    contrast: (
        100: #000000,
        500: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #ffffff,
    ),
);

$chev-red: (
    100: $light-red,
    500: $medium-red,
    700: $dark-red,
    contrast: (
        500: #ffffff,
    ),
);

$LUN-primary: mat-palette($chev-blue);
$LUN-accent: mat-palette($chev-blue, 900);
// The warn palette is optional (defaults to red).
$LUN-warn: mat-palette($chev-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$LUN-theme: mat-light-theme(
    (
        color: (
            primary: $LUN-primary,
            accent: $LUN-accent,
            warn: $LUN-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($LUN-theme);

/* You can add global styles to this file, and also import other style files */

.mat-LUNlight {
    background-color: map-get($LUN-primary, 100);
    color: #fff;
}

.mat-LUNgreenlight {
    background-color: $light-green;
    color: #fff;
}

.mat-LUNgreen {
    background-color: $medium-green;
    color: #fff;
}

*:focus {
    outline: 0;
}

body {
    background-color: white;
    font-family: 'GothamNarrowBook', Arial, sans-serif;
}

.layout-39 {
    width: 1440px;
    background-color: white;
    margin: 0 auto;
    overflow: hidden;

    .inline-block {
        display: inline-block;
    }
}

.main {
    max-width: 1320px;
}

app-navbar nav#site-specific-nav {
    background: $luncolordark;

    ul:not(.in-mega) > li:hover {
        background: $luncolor;
    }
}

.margin {
    &-left {
        &20 {
            margin-left: 20px;
        }
    }

    &-right {
        &15 {
            margin-right: 15px;
        }

        &20 {
            margin-right: 20px;
        }

        &40 {
            margin-right: 40px;
        }
    }
}

a:not([href]):not([tabindex]) {
    color: $linkcolor;
}

h4 {
    color: $luncolor;
    text-align: left;
    margin: 10px 0 0 0;
}

// BUTTONS
.buttons-container.flex {
    display: flex;

    &.center {
        justify-content: center;
    }
}

.lun-button {
    background-color: $luncolor;
    font-size: 16px;
    text-align: center;
    height: 40px;
    width: 210px;
    font-family: 'GothamBook';
    border: 1px solid white;
    color: white;
    border-radius: 1px;

    &:hover {
        background-color: $luncolorlight;
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        background-color: rgba(68, 68, 68, 0.1);
        border: none;
        color: rgb(103, 103, 103, 0.5);
        cursor: default;
    }

    &.lun-button-light {
        background-color: $luncolorlight;
        border-color: $luncolorlight;

        &:hover {
            background-color: $luncolor;
            border-color: $luncolor;
        }

        &:disabled {
            background-color: rgba(68, 68, 68, 0.1);
            border: none;
            color: rgb(103, 103, 103, 0.5);
        }
    }

    &.lun-button-white {
        background-color: white;
        border: 1px solid rgba(68, 68, 68, 0.4);
        color: #676767;

        &:hover {
            color: black;
            border-color: rgba(0, 0, 0, 0.4);
        }

        &:disabled {
            background-color: rgba(68, 68, 68, 0.1);
            border: none;
            color: rgb(103, 103, 103, 0.5);
            cursor: default;
        }
    }
    &.lun-button-red {
        background-color: $medium-red;
        border-color: $medium-red;

        &:hover {
            background-color: $dark-red;
            border-color: $dark-red;
        }

        &:disabled {
            background-color: rgba(68, 68, 68, 0.1);
            border: none;
            color: rgb(103, 103, 103, 0.5);
        }
    }
    &.lun-button-green {
        background-color: $light-green;
        border-color: $light-green;

        &:hover {
            background-color: $medium-green;
            border-color: $medium-green;
        }

        &:disabled {
            background-color: rgba(68, 68, 68, 0.1);
            border: none;
            color: rgb(103, 103, 103, 0.5);
        }
    }
}

button {
    cursor: pointer;
}

//statuses
.lun-status {
    font-family: 'GothamBookMedium', Arial, sans-serif;
    width: 80px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid black;
    font-size: 11px;

    &.lun-status-big {
        width: 90px;
        padding: 2px 0;
        font-size: 14px;
    }

    &.cancelled-status {
        color: #e21836;
        border: 1px solid #e21836;
        background: rgba(226, 24, 54, 0.2);
    }

    &.void-status {
        color: #97002e;
        border: 1px solid #97002e;
        background: rgba(151, 0, 46, 0.2);
    }

    &.submitted-status {
        color: #444b0d;
        border: 1px solid #444b0d;
        background: rgba(68, 75, 13, 0.2);
    }

    &.costing-status {
        color: #0066b2;
        border: 1px solid #0066b2;
        background: rgba(118, 146, 49, 0.2);
    }

    &.approved-status {
        color: #0066b2;
        border: 1px solid #0066b2;
        background: rgba(0, 102, 178, 0.2);
    }

    &.processing-status {
        color: #769231;
        border: 1px solid #769231;
        background: rgba(118, 146, 49, 0.2);
    }

    &.draft-status {
        color: #b2cc34;
        border: 1px solid #b2cc34;
        background: rgba(178, 204, 52, 0.2);
    }

    &.reviewed-status {
        color: #607208;
        border: 1px solid #639e15;
        background: rgba(68, 231, 36, 0.4);
    }
}

//Accordion
mat-accordion mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header {
    padding: 8px 18px 8px 24px;
}

.mat-expansion-indicator::after {
    color: $luncolorlight;
}

.mat-expansion-panel-header {
    .mat-chip.mat-standard-chip {
        background-color: #cceffc;
    }

    .mat-standard-chip {
        font-size: 11px;
        color: #00adef;
        border: 1px solid #0db1ef;
        border-radius: 3px;
        min-height: 24px;
    }

    .mat-basic-chip {
        display: inline-flex;
        font-size: 11px;
        border: 1px solid #0db1ef;
        padding: 0 8px 0 4px;
        border-radius: 3px;
        color: #00adef;
        background-color: #cceffc;
        align-items: center;
        cursor: default;
        min-height: 24px;
    }

    .mat-chip .mat-chip-trailing-icon.mat-icon,
    .mat-chip .mat-chip-remove.mat-icon {
        font-size: 18px;
        height: 18px;
        width: 18px;
        margin-left: 4px;
        color: rgba(0, 0, 0, 0.87);
    }

    .mat-chip-list-wrapper .mat-basic-chip,
    .mat-chip-list-wrapper input.mat-input-element {
        margin: 4px;
    }

    .mat-chip.mat-basic-chip .mat-chip-remove {
        opacity: 0.4;
        cursor: pointer;
    }

    .mat-expansion-indicator {
        margin: 0 4px;
    }
}

//mypage
.mypage-subcontainer {
    position: relative;
    background-color: white;
    min-height: 310px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 30px 30px 30px 30px;
    margin-bottom: 20px;

    .mat-cell a {
        cursor: pointer;

        &:hover {
            color: $linkcolor;
            border-bottom: 1px solid $linkcolor;
        }
    }
}

.sub-title {
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-family: 'GothamNarrowBold', Arial, sans-serif;
    font-size: 20px;
    text-transform: lowercase;
    color: $luncolor;
    margin-bottom: 20px;
}

//IE fix for mat-chip in mat accordion Bug 1636922, 1656306
/* prettier-ignore */
@media screen and (min-width: 0\0) and (min-resolution: +72dpi), \0screen\,screen\9 {
    mat-accordion mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header {
        height: auto !important;
    }

    .mat-expansion-panel-header .mat-content {
        flex-wrap: wrap;

        > div {
            flex: 1 1 100%;
        }
    }
}

.results-container {
    .title-date {
        font-family: 'GothamNarrowBold';
        font-size: 20px;
        text-transform: lowercase;
        color: $luncolor;
    }
    .results-title {
        margin-bottom: 20px;
    }
}

//TABLES

th.mat-header-cell[class*='mat-column-'] {
    font-size: 11.5px;
    color: #222222;
}

td.mat-cell[class*='mat-column-'] {
    font-size: 12.5px;
}

table {
    td.mat-cell,
    th.mat-header-cell {
        padding: 0 3px;

        &:first-of-type {
            padding-left: 16px;
        }

        &:last-of-type {
            padding-right: 16px;
        }
    }

    th.mat-header-cell {
        padding: 0 2px;
        font-family: 'GothamNarrowBold', Arial, sans-serif;
        color: #222222;
        border-bottom: 1px solid #808080;
        border-top: 1px solid #c5c5c5;
        background: #fbfbfb;
    }

    tbody tr:nth-child(odd) td {
        background: #ffffff;
    }

    th.mat-header-cell.mat-column-mcForecast .mat-sort-header-container {
        justify-content: center;
    }
}

td.mat-column {
    &-subject {
        word-break: break-all;
        word-break: break-word;
    }
}

.scroll {
    overflow-x: auto;

    table.mat-table {
        table-layout: fixed;
    }
}

//Changes for angular materials
.filter-container {
    input.mat-input-element,
    mat-select.mat-select {
        font-weight: 600;
    }
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $luncolorlight;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: $luncolor;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $luncolor;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $luncolorlight;
}

.mat-option.mat-selected:not(.mat-option-disabled) {
    color: $luncolor;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
    background: $luncolorlight;
}

.filter-container {
    .container50 .mat-form-field-infix {
        width: 50px;
    }
    .container150 .mat-form-field-infix {
        width: 150px;
    }

    .container300 .mat-form-field-infix {
        width: 300px;
    }

    .container367 .mat-form-field-infix {
        width: 367px;
    }

    .container420 .mat-form-field-infix {
        width: 420px;
    }

    .container610 .mat-form-field-infix {
        width: 610px;
    }

    .container620 .mat-form-field-infix {
        width: 620px;
    }
}

.mat-column-mcForecast .mat-button-focus-overlay {
    background: none;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $luncolorlight;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $luncolorlight;
}

//checkboxes
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $luncolorlight;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background-color: $luncolorlight;
}

//table columns checkboxes
.table-checkboxes {
    .mat-checkbox {
        margin-right: 25px;

        &:last-child {
            margin-right: 0px;
        }
    }

    .mat-checkbox-label {
        font-family: 'GothamNarrowBold', Arial, sans-serif;
        font-size: 12px;
        color: #222222;
    }
}

.mat-autocomplete-panel tr:not(.tr-selected) {
    cursor: pointer;
}

app-multiple-items-selector .mat-form-field-flex {
    cursor: pointer;
}

app-multiple-items-selector .mat-form-field-flex input {
    cursor: pointer;
}

tr.tr-selected td {
    color: #b2b2b2;
}

.mat-autocomplete-panel h4 {
    padding-top: 10px;
}

.mat-chip-list {
    width: 100%;
}

.mat-chip-list input.mat-chip-input {
    width: 100%;
}

_:-ms-fullscreen,
:root .mat-chip-list-wrapper {
    display: block;
}

.filter-loader,
.delta-loader,
.upload-log-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.6);
    z-index: 1000;
}

.lds-hourglass:after {
    content: ' ';
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
    border: 25px solid #0066b2;
    border-color: #0066b2 transparent #0066b2 transparent;
    animation: lds-hourglass 1.2s infinite;
}

.lds-hourglass-small:after {
    content: ' ';
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 10px solid #0066b2;
    border-color: #0066b2 transparent #0066b2 transparent;
    animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    100% {
        transform: rotate(1800deg);
    }
}

.mat-expansion-panel {
    position: relative;
}

//configuration tabs
.main .mat-tab-group.mat-primary .mat-ink-bar,
.main .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $luncolorlight;
}

a.mat-tab-link {
    &:visited,
    &:hover {
        border-bottom: none;
        color: rgba(0, 0, 0, 0.87);
    }
}

.main .mat-tab-link:not(.mat-tab-label-active) {
    background-color: rgba(216, 216, 216, 0.4);
}

//changed document mat-tab
.mat-tab-header {
    border-bottom: none;
}

.mat-tab-body {
    padding: 30px;

    &-wrapper {
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
}

.mat-tab-label {
    background-color: rgba(216, 216, 216, 0.4);

    &-active {
        color: black;
        background-color: white;
    }
}

//Table header filters
.mat-calendar-body-selected {
    background-color: $luncolorlight;
}

app-asc-desc-order .sort-button.mat-icon-button {
    line-height: 24px;
}

app-header-checklist-filter .button-container {
    margin-bottom: 10px;
}

app-header-checklist-filter {
    .mat-list-base .mat-list-option .mat-list-item-content {
        height: 32px;
        padding: 0 2px;
    }

    .mat-list-base .mat-list-option.mat-list-option .mat-list-item-content .mat-list-text {
        padding-left: 8px;
    }

    .mat-accent .mat-pseudo-checkbox-checked {
        background: $luncolorlight;
    }
}

.filter-container .mixedapp-container .container300 .mat-form-field-infix {
    width: 300px;
}

.filter-container .mixedapp-container .container620 .mat-form-field-infix {
    width: 620px;
}

.filter-container .mixedapp-container .container940 .mat-form-field-infix {
    width: 940px;
}

.page-counter {
    button.mat-paginator-navigation-next.mat-icon-button {
        margin-left: 58px;
    }

    &.paginator {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: rgba(0, 0, 0, 0.54);
        padding: 0 8px;
        width: 1300px;

        input {
            font-family: Roboto, 'Helvetica Neue', sans-serif;
            font-size: 12px;
            text-align: center;
            margin-left: 8px;
            border: none;
            outline: none;
            width: 50px;
            color: rgba(0, 0, 0, 0.54);
            position: relative;
            right: 150px;
            cursor: text;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}

/* //Validation status

.validation-status {
    font-family: 'GothamBookMedium';
    width: 90px;
    text-align: center;
    line-height: 20px;
    padding: 2px 0;
    border-radius: 2px;
    font-size: 11px;
}

.validation-status.completed-status {
    color: #00adee;
    border: 1px solid #00adee;
    background: rgba(0, 173, 238, 0.2);
}

.validation-status.changes-reviewed-status {
    color: #444b0d;
    border: 1px solid #444b0d;
    background: rgba(68, 75, 13, 0.2);
}

.validation-status.in-progress-status {
    color: #4b5216;
    border: 1px solid #4b5216;
    background: rgba(68, 75, 13, 0.4);
}

.validation-status.not-started-status {
    color: #b2cc34;
    border: 1px solid #b2cc34;
    background: rgba(178, 204, 52, 0.2);
}

app-history-popup {
    .validation-status {
        &.completed-status {
            display: block;
            padding: 0;
        }

        &.in-progress-status {
            display: block;
            padding: 0;
        }

        &.not-started-status {
            display: block;
            padding: 0;
        }

        &.changes-reviewed-status {
            display: block;
            line-height: 1;
            padding: 2px 0;
        }
    }
} */

.checkbox-section .mat-checkbox-layout .mat-checkbox-inner-container {
    margin: initial;
    margin-right: 8px;
}

//TABLE
//Register-Table-Results
//Register-Table-Cursor
.results-container tbody td:first-of-type a {
    cursor: pointer;
}

.results-container .mat-cell a:hover {
    color: $linkcolor;
    border-bottom: 1px solid $linkcolor;
}

//tooltip
mat-tooltip-component .luntooltip {
    background-color: white;
    color: black;
    font-size: 14px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}

.mat-tooltip.linebreak {
    white-space: pre-line;
}

.mat-select-value-text {
    font-family: 'GothamNarrowBold';
}

.main > .navigator .mat-icon {
    margin-right: 2px;
}

.main > a.navigator {
    cursor: pointer;
    color: $luncolorlight;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 24px;
    margin-bottom: -8px;
}

.main > a:hover.navigator {
    color: $luncolorlight;
    border-bottom: none;
}

th[app-col-resize] {
    cursor: col-resize;
}

/* 
[app-col-resize] th {
    position: relative;

    &::before {
        cursor: col-resize;
        user-select: none;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 2em;
    }
} */

.hover-pointer {
    cursor: pointer;
}

.hover-pointer.resize-column {
    cursor: col-resize;
}

.multiple-items-error .mat-form-field-label {
    color: $medium-red;
}

.multiple-items-error .mat-form-field-underline {
    background-color: $medium-red;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.6);
    z-index: 1000;
}

.header-underline {
    text-decoration: underline;
}

.checkbox-section .mat-checkbox-layout {
    white-space: nowrap;
}

.sort-container {
    display: flex;
    align-items: center;

    &.center {
        justify-content: center;

        span {
            text-align: center;
        }
    }

    .sort-icon {
        display: flex;
        align-self: center;
        height: 18px;

        .mat-icon {
            height: 18px;
            width: 18px;
            font-size: 18px;
        }
    }
}

.nobreak.mat-column-contractno {
    white-space: nowrap;
}

.jodit-container {
    position: relative;
    border: 1px solid black;
    padding: 5px;
    cursor: pointer;
    min-height: 150px;

    &.description-error {
        border: 1px solid $medium-red;
    }

    &.description-disabled {
        pointer-events: none;
        border: 1px solid gray;
        background-color: rgb(235, 235, 228);
    }
}

.jodit-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.6);
}

.started-status,
.finished-status,
.error-status,
.info-status,
.warning-status {
    font-family: 'GothamBookMedium';
    width: 80px;
    text-align: center;
    border-radius: 2px;
    font-size: 11px;
}

.started-status {
    color: #ab7b03;
    border: 1px solid #ffb600;
    background: rgba(255, 182, 0, 0.2);
}

.finished-status {
    color: #80941d;
    border: 1px solid #b2cc34;
    background: rgba(178, 204, 52, 0.2);
}

.error-status {
    color: #bd305b;
    border: 1px solid #bd305b;
    background: rgba(189, 48, 91, 0.2);
}

.info-status {
    color: #00adee;
    border: 1px solid #00adee;
    background: rgba(0, 173, 238, 0.2);
}
.warning-status {
    color: #787908;
    border: 1px solid #d3da6a;
    background: rgba(232, 247, 6, 0.5);
}

.warning-status {
    color: blueviolet;
    border: 1px solid blueviolet;
    background: lavender;
}

app-manual-upload {
    .in-progress {
        padding-right: 20px;
    }
}

app-manual-upload {
    .in-progress::after {
        content: ' ';
        position: relative;
        top: 4px;
        left: 10px;
        display: inline-block;
        border-radius: 50%;
        width: 0;
        height: 0;
        box-sizing: border-box;
        border: 10px solid $luncolor;
        border-color: $luncolor transparent $luncolor transparent;
        animation: lds-hourglass 1.2s infinite;
    }
}

.upload-log-container {
    th.mat-header-cell.mat-column {
        &-type,
        &-status,
        &-startDate,
        &-endDate {
            width: 90px;
        }

        &-infoMessage {
            min-width: 340px;
        }

        &-errorMessage {
            min-width: 100px;
        }

        &-user {
            width: 108px;
        }

        &-downloadResult {
            width: 86px;
        }
    }

    td.mat-cell.mat-column {
        &-errorMessage {
            word-break: break-all;
            word-break: break-word;
        }
    }

    th.mat-column-downloadResult,
    td.mat-column-downloadResult {
        text-align: center;
    }
}

.downloadIcon {
    cursor: pointer;
}

//manual uploads
.section-header {
    color: $luncolor;
}

.import-container {
    font-family: Roboto, 'Helvetica Neue', sans-serif;

    .mat-icon {
        color: $luncolorlight;
        font-size: 20px;
        line-height: 1;

        &-button {
            margin-left: -2px;
            width: 24px;
            height: 24px;
            line-height: 1;

            &:focus,
            &:active {
                outline: none;
            }
        }
    }
}

.import-container,
.delta-container,
.delete-container,
.upload-log-container {
    position: relative;
    background-color: white;
    margin: 0 0 20px 0;
    min-height: 60px;
    width: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 30px;
}

.empty {
    background-color: white;
    border: 1px;
}

.upload-log-container {
    h4 {
        margin-bottom: 20px;
    }
}

.live-value,
.import-value {
    min-height: 20px;
    color: rgba(0, 0, 0, 0.6);
}

.import-value {
    color: $luncolor;
    margin-top: 5px;
}

.delta-container .mat-column {
    &-projectTeamName {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    &-actions {
        text-align: center;

        > div {
            float: right;
        }
    }
}

.changebuttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 15px 15px;
}

.legend-scale ul {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    float: left;
    list-style: none;

    li {
        font-size: 80%;
        list-style: none;
        margin-left: 0;
        line-height: 18px;
        margin-bottom: 2px;
    }
}

ul.legend-labels li span {
    display: block;
    float: left;
    height: 16px;
    width: 30px;
    margin-right: 5px;
    margin-left: 0;
    border: 1px solid #999;
}

//overwrite ngx-text-diff styles
.td-btn-group button.active,
.td-btn-group button:hover {
    background-color: $luncolor !important;
    border: 1px solid $luncolor !important;
}

.td-btn-group button {
    background-color: $luncolorlight !important;
    border: 1px solid $luncolorlight !important;
}

.td-checkbox-container input:checked ~ .checkmark {
    background-color: $luncolorlight !important;
}

.customdiffwrap .line-by-line .td-table td.content-col {
    white-space: normal;
}

// MEDIA QUEREIES
//overwrite Next.css to not hide navbar & tables
@media (max-width: 1199.98px) {
    nav#site-specific-nav {
        display: block;
        width: 100%;
    }

    div table {
        margin-top: 0;
    }

    div table tr {
        th,
        td {
            display: table-cell;
        }
    }
}

@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    app-multiple-items-selector .mat-chip-list-wrapper input.mat-input-element {
        margin: 2px;
    }
}

@media (resolution: 144dpi) {
    body {
        zoom: (0.85);
    }
}

@media print {
    .doNotPrint {
        display: none !important;
    }

    .title-container .title {
        color: #000 !important;
    }

    .main h4 {
        color: #464646 !important;
    }

    thead {
        display: table-header-group;
    }

    .background {
        background-color: white !important;
    }

    input[readonly='true'],
    .mat-select-value-text,
    .mat-input-element:disabled,
    .mat-select-disabled .mat-select-value,
    .mat-chip.mat-standard-chip.mat-chip-disabled,
    .mat-form-field-appearance-legacy .mat-form-field-label,
    .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
        font-family: 'GothamNarrowBook', Arial, sans-serif !important;
        color: #000 !important;
        opacity: 1 !important;
        font-weight: 400 !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: #808080 !important;
        background-image: none !important;
    }

    mat-icon.mat-icon.mat-chip-remove {
        display: none;
    }

    .jodit-container {
        background-color: white !important;
        border: none !important;
    }

    mat-chip.mat-standard-chip {
        background-color: white !important;
        font-size: 16px;
    }
}
