$luncolorlight: #00adee;
$luncolor700: #027199;
$luncolor: #0066b2;
$luncolordark: #0b2d71;

$linkcolor: #0066b2;

$light-red: #e21836;
$medium-red: #97002e;
$dark-red: #58001c;

$light-green: #b2cc34;
$medium-green: #769231;
$dark-green: #444b0d;
